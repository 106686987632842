body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.order-summary {
  border: 1px solid #c4c4c4;
  width: 100%;
  padding: 5px 5px 3px 5px;
  /* margin-top:10px; */
  margin-bottom: 10px;
  border-radius: 4px;
}

.order-summary .summary-title {
  text-align: center;
  border-radius: 4px;
  font-weight: 400;
  font-size: 13px;
  background-color: #5d6e7e;
  color: white;
  padding: 3px 5px;
  margin-bottom: 5px;
}

.order-summary .summary-heading {
  font-weight: 500;
  font-size: 12px;
}

.summary-value {
  text-align: right;
  font-size: 12px;
}

/**Antd Table **/

.ant-table-tbody>tr>td {
  border-bottom: 1px solid #e3e3e3 !important;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th {
  border-right: none !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  font-size: 17px !important;
  vertical-align: 0px !important;
}

.ant-card-head {
  border-bottom: 1px dotted black !important;
}

.ant-card-bordered {
  background: #fff !important;
  border: 1px solid #e5e5e5 !important;
}

.ant-form-item-label>label {
  font-weight: 600;
}

.h-100vh {
  height: 100vh;
}

.ant-table-container {
  border: 1px solid #c4c4c4 !important;
}

.anticon-spin {
  color: black !important;
}

.ant-tabs-nav-operations {
  display: flex !important;
  position: absolute !important;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-more {
  display: none;
}


.global_search_oms > div{
  /* background-color: #6F3700 !important; */
  width: 256px !important;
}

@media print {
  .oms-sidebar {
    display: none;
  }

  .submit-btn-btm {
    display: none !important;
  }

  .ant-pagination {
    display: none !important;
  }

  .business_header {
    margin: 0 !important;
  }

  .row {
    margin: 0;
  }

  .business_header {
    margin: 0 !important;
  }

  .ledger_print {
    margin: 0;
    width: 1000px !important;
  }

  .table {
    width: 960px !important;
  }

  .table_content {
    margin-right: 40px;
  }

  .months {
    background-color: rgb(180, 180, 180) !important;
  }

  /* .ant-table-column-title{
	max-width: 60px;
  } */
  /*optional*/
  .ant-table-column-sorter {
    display: none;
  }

  .ant-dropdown-trigger {
    display: none;
  }

  td {
    font-size: 10px;
  }

  .ant-table-cell>div {
    width: max-content;
  }

  /*optional*/
  .maxWidth {
    /* max-width: fit-content; */
    width: fit-content;
    margin: 0 !important;

  }
  .fitWidth {
    /* max-width: fit-content; */
    width: fit-content;
    margin: 0 !important;

  }

  table {
    width: 100% !important;
    /* margin: 0 !important; */
  }

  .col {
    padding: 0 !important;
  }

  /*new css*/
  .ant-select-selector {
    /* border-color: white !important; */
    /* border-bottom: 1px solid black !important; */
  }

  .ant-select-arrow {
    display: none;
  }

  .ant-picker {
    /* border-color: white !important; */
    /* border-bottom: 1px solid black !important; */
  }

  .ant-picker-suffix {
    display: none;
  }
}

@media print {
  @page {
    /* specify the layout option */
    size: auto;
  }
}


.fix-scroll-table > div > div > div > div > div{
  overflow: auto !important;
  height: calc(100vh - 500px) !important;
  border: none !important;
}
.table-header-sticky .ant-table-thead {
  position: sticky !important;
  top: 0 !important;
  z-index: 111 !important;
}
@media screen and (max-width:1024px) {
  .overflow{
    overflow: auto;
    height: 80vh;
  }
  .fix-scroll-table > div > div > div > div > div {
    overflow: auto !important;
    height: calc(100vh - 222px) !important;
    border: none !important;
}
}


.border-line-th .ant-table-thead>tr:first-child th:last-child:before{
  height: 100% !important;
  left: -1px !important;
  top: 0px;
  border-left: 1px solid #bbbbbb !important;
}

.Border-Right-table:before {
  height: 100% !important;
  left: -1px !important;
  top: 0px;
  border-left: 1px solid #bbbbbb !important;
}
.border-z-index-1:before {
  z-index: 1;
}
/* .Border-Right-table-top:before {
  top: 0%!important;
  transform: translateY(0%)!important;
} */

/* .border-line-th .ant-table-thead>tr>th:not([colspan]):before {
  height: 100% !important;
  left: -1px !important;
  border-right: 1px solid #bbbbbb !important;

  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, .06);
  transform: translateY(-50%);
  transition: background-color .3s;
  content: "";

} */
.border-line-th .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  height: 100% !important;
  left: -1px !important;
  border-right: 1px solid #bbbbbb !important;
}
.bottom-align-th table tr th.ant-table-cell {
  vertical-align: bottom !important;
}
.bottom-align-td table tr td.ant-table-cell {
  vertical-align: bottom !important;
}

.table-td-border-right .ant-table-row .ant-table-cell {
  border-right: 1px solid #bbbbbb !important;
}
.td-general-style td {
  padding: 3px!important;
  font-size: 11.33px !important;
}
.th-general-style th {
  padding: 4px!important;
  font-size: 11.33px !important;
}
.even-tr-style tr:nth-child(even){
  background-color: #f2f2f2 !important;
}

.border .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td{
  border-right: 1px solid #bbbbbb !important;
}

.ant-table-cell{
  text-transform: capitalize !important;
}